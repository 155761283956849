<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filter-vals.sync="filterVals"
    :filters="filters"
    :extra-actions="extraActions"
    details-view
    single-edit
    authority="Users_User"
    enable-disable-toggle
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'

import storeModule from '../userStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
      companyOptions: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'users',
        endpoint: 'users',
        route: 'users',
        title: {
          single: this.$t('User'),
          plural: this.$t('Users'),
        },
        limited: this.$store.getters['app-common/maxUsers'],
      }
    },

    columns() {
      return [
        { key: 'id', label: this.$t('id'), sortable: true },
        { key: 'participant.firstName', label: this.$t('First Name'), sortable: true },
        { key: 'participant.lastName', label: this.$t('Last Name'), sortable: true },
        { key: 'participant.email', label: this.$t('Email'), sortable: true },
        { key: 'company.title', label: this.$t('Company'), sortable: true },
        { key: 'enabled', label: this.$i18n.t('enabled') },
        { key: 'actions', label: this.$t('actions') },
      ]
    },

    formattedColumns() {
      return [{ key: 'enabled', type: 'boolean' }]
    },

    filters() {
      return [
        {
          name: 'company',
          label: this.$t('Company'),
          options: this.companyOptions,
        },
      ]
    },
    extraActions() {
      return [
        {
          endpoint: 'reset-password/email',
          icon: 'SendIcon',
          title: 'Reset Password Email',
          renderCondition: {
            key: 'enabled',
            value: true,
          },
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch('app-common/fetchCompanies').then(response => {
      this.companyOptions = response.data
    })
  },
}
</script>
